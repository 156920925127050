<template>
  <aside
    ref="sidebar"
    data-cy="sidebar__container"
    class="flex flex-col h-full min-h-screen p-6 bg-green-600"
  >
    <section
      ref="sidebar-title"
      data-cy="sidebar__header"
      class="flex gap-2"
    >
      <img
        src="@/assets/icons/logo.svg"
        alt="Portal Jabar Logo"
        width="34px"
        height="34px"
        class="self-start"
      >
      <div>
        <img
          src="@/assets/icons/logo-text.svg"
          alt="Portal Jabar Logo"
          width="75px"
          height="38px"
          class="mb-1"
        >
        <h1
          data-cy="sidebar__header-title"
          class="text-sm font-bold leading-5 text-white font-lato whitespace-nowrap"
        >
          CONTENT MANAGEMENT<br>
          SYSTEM
        </h1>
      </div>
    </section>
    <nav>
      <ul
        ref="sidebar-navigation"
        data-cy="sidebar__navigation"
        class="w-full mt-10"
      >
        <router-link
          v-for="navigation in navigationMenu"
          :key="navigation.label"
          v-slot="{ href, navigate, isActive }"
          :to="navigation.link"
          :exact="navigation.link === '/'"
          custom
        >
          <li
            v-if="isAllowedToAccess(navigation.permission)"
            class="w-full min-h-[50px] p-[15px] flex items-center rounded-lg font-lato
          font-bold text-sm text-white hover:bg-green-700 mb-2"
            :class="{'bg-green-700' : isActive}"
          >
            <a
              :href="href"
              :title="navigation.label"
              class="flex items-center w-full gap-3 whitespace-nowrap"
              @click="navigate"
            >
              <!-- Menu Icon -->
              <component
                :is="navigation.icon"
                class="w-5 h-5 fill-white"
              />
              {{ navigation.label }}
              <span
                v-show="navigation.arrow"
                class="ml-auto"
              >
                <JdsIcon
                  fill="#fff"
                  name="chevron-right"
                  size="16px"
                />
              </span>
            </a>
          </li>
        </router-link>
      </ul>
    </nav>
    <section class="mt-auto">
      <ul
        ref="sidebar-bottom-nav"
        data-cy="sidebar__bottom-nav"
        class="mt-auto"
      >
        <!-- Create J-Site -->
        <router-link
          v-if="!hasMicrosite && $hasPermission('j-site.site-management.create')"
          v-slot="{ href, navigate }"
          to="/j-site/onboarding"
          data-cy="j-site-create__button-create"
          custom
        >
          <li
            class="w-full min-h-[50px] p-[15px] flex items-center rounded-lg font-lato
            font-bold text-sm text-white hover:bg-green-700 mb-2"
            :class="{'bg-green-700' : $route.path.includes('j-site/tambah')}"
          >
            <a
              :href="href"
              title="Kelola J-Site"
              class="flex items-center w-full gap-3 whitespace-nowrap"
              @click="navigate"
            >
              <MicrositeIcon class="w-5 h-5" />
              Buat J-Site
            </a>
          </li>
        </router-link>

        <!-- Manage J-Site -->
        <router-link
          v-else-if="hasMicrosite && $hasPermission('j-site.site-management.read')"
          v-slot="{ href, navigate, isActive }"
          to="/j-site"
          data-cy="j-site-create__button-manage"
          custom
        >
          <li
            class="w-full min-h-[50px] p-[15px] flex items-center rounded-lg font-lato
          font-bold text-sm text-white hover:bg-green-700 mb-2"
            :class="{'bg-green-700' : isActive}"
          >
            <a
              :href="href"
              title="Kelola J-Site"
              class="flex items-center w-full gap-3 whitespace-nowrap"
              @click="navigate"
            >
              <MicrositeIcon class="w-5 h-5" />
              Kelola J-Site
            </a>
          </li>
        </router-link>

        <!-- General Settings -->
        <router-link
          v-slot="{ href, navigate, isActive }"
          to="/pengaturan"
          custom
        >
          <li
            class="w-full min-h-[50px] p-[15px] flex items-center rounded-lg font-lato
          font-bold text-sm text-white hover:bg-green-700 mb-2"
            :class="{'bg-green-700' : isActive}"
          >
            <a
              :href="href"
              title="Pengaturan Akun"
              class="flex items-center w-full gap-3 whitespace-nowrap"
              @click="navigate"
            >
              <SettingIcon class="w-5 h-5 fill-white" />
              Pengaturan Akun
            </a>
          </li>
        </router-link>
      </ul>
    </section>
  </aside>
</template>

<script>
import { NAVIGATION_MENU } from '@/common/constants';
import { mapGetters } from 'vuex';
import DashboardIcon from '@/assets/icons/dashboard.svg?inline';
import ServiceIcon from '@/assets/icons/layanan.svg?inline';
import AgendaIcon from '@/assets/icons/agenda.svg?inline';
import NewsIcon from '@/assets/icons/berita.svg?inline';
import SettingIcon from '@/assets/icons/setting.svg?inline';
import LandingPageIcon from '@/assets/icons/landing-page.svg?inline';
import MicrositeIcon from '@/assets/icons/microsite.svg?inline';
import GedungSateIcon from '@/assets/icons/gedung-sate.svg?inline';

export default {
  name: 'Sidebar',
  components: {
    DashboardIcon,
    ServiceIcon,
    AgendaIcon,
    NewsIcon,
    SettingIcon,
    LandingPageIcon,
    MicrositeIcon,
    GedungSateIcon,
  },
  data() {
    return {
      navigationMenu: NAVIGATION_MENU,
    };
  },
  computed: {
    ...mapGetters('microsite', ['hasMicrosite']),
  },
  methods: {
    isAllowedToAccess(permission) {
      if (!permission || permission === null) {
        return true;
      }

      if (permission && this.$hasPermission(permission)) {
        return true;
      }

      return false;
    },
  },
};
</script>
